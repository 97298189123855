import React from "react";
import {AppContext} from "../AppContext";
import {Resource} from "../../lib/resource";
import Tokens from "./Tokens";
import Token from "./Token";
import TextInput from "./TextInput";
import Line from "./Line";
import {renderFields} from "./FieldRenderer";

type Props = {
    ctx: AppContext
    resource: Resource | null
    handler: () => void
    errors: string
    setErrors: (s:string) => void
}


const ResourceInput: React.FC<Props> = (props) => {
    const r = props.resource;
    const fields = r === null ? [] : renderFields({resource: r, handler: props.handler});

    return (
        <pre>
                {r !== null &&
                <Line>
                    <Tokens>
                        <Token type={"token"}>{r.type}</Token>
                        <Token type={"keyword"} quote>{r.fullName}</Token>
                        <Token type={"value"}>
                            <TextInput
                                inputType={"text"}
                                fieldName={r.name}
                                value={r.blockId}
                                placeholder={`* ${props.ctx.getDefaultBlockId(r)}`}
                                error={props.errors !== ""}
                                helperText={props.errors}
                                onChange={(e) => {
                                    r.blockId = e.target.value;
                                    // workbench level validation
                                    const err = props.ctx.validateUniqueId(r);
                                    props.setErrors(err);
                                    props.handler();
                                }}
                            />
                        </Token>
                        <Token>{"{"}</Token>
                    </Tokens>
                </Line>
                }
            {fields}
            <Line>
                    <Token>{"}"}</Token>
                </Line>
            </pre>
    )
};

export default ResourceInput;