import React, {useState} from 'react';
import {AppContext} from "./AppContext";
import Editor from "./Editor";
import SingleView from "./SingleView";

const App: React.FC = () => {
    const [ctx, updateContext] = useState(new AppContext());
    ctx.updateContext = updateContext;

    // routing by hash
    const hash = window.location.hash.replace("#", "");
    let useSingleView = false;
    if (hash !== "") {
        const [type, name] = hash.split("/");
        useSingleView = ctx.setCurrentEditTarget(type, name);
    }

    return useSingleView ? <SingleView ctx={ctx} /> : <Editor ctx={ctx}/>;
};

export default App;

