import {AppContext} from "../AppContext";
import React, {useCallback, useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid, Theme} from "@material-ui/core";
import {Resource} from "../../lib/resource";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import UpdateIcon from '@material-ui/icons/Update';
import Slide from "@material-ui/core/Slide";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {dark} from "react-syntax-highlighter/dist/esm/styles/prism";
import CopyToClipboard from 'react-copy-to-clipboard';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ResourceInput from "./ResourceInput";

type Props = {
    ctx: AppContext
    resource: Resource | null
    singleView?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        height: 800,
        width: "98%",
        marginLeft: 10,
    },
    editor: {
        marginTop: "10px",
        backgroundColor: "rgb(76, 63, 51)",
        width: "100%",
        border: "0.3em solid rgb(122, 102, 81)",
        borderRadius: "0.5em",
        boxShadow: "black 1px 1px 0.5em inset",
        fontSize: "large",
        fontFamily: `Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace`,
        color: "white",
        textShadow: "black 0px -0.1em 0.2em",
        marginRight: "10px",
        overflow: "auto",
        height: "100%"
    },
    viewerCode: {
        lineHeight: 40,
        paddingTop: 40,
    },
    buttons: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        position: "relative",
        height: 45,
    },
    baseButton: {
        marginRight: theme.spacing(1),
    },
    addButton: {},
    updateButton: {},
    validateButton: {
        position: "absolute",
        left: 0,
        top: 16,
    },
    closeButton: {
        position: "absolute",
        right: -5,
        top: 16,
    },
    deleteButton: {
        position: "absolute",
        right: -15,
        top: 16,
    },
    clearButton: {
        position: "absolute",
        right: -15,
        top: 16,
    },
    copyButton: {},
}));

const ResourceEditor: React.FC<Props> = (props) => {
    const classes = useStyles();
    const ctx = props.ctx;
    const r = props.resource;
    const isNew = r !== null && r.isNew;

    const handleChange = useCallback(() => props.ctx.notifyChanged(), [props.ctx]);
    const [errors, setErrors] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [validateResultSnack, setValidateResultSnack] = useState(false);

    // submit
    const handleSubmit = useCallback((e) => {
        if (!r) {
            return
        }

        r.validate();
        // resource level validation
        if (r.hasError) {
            ctx.notifyChanged();
            return;
        }

        // workbench level validation
        const err = ctx.validateUniqueId(r);
        setErrors(err);
        if (err !== "") {
            return;
        }

        ctx.addNode(r.clone());

        r.clearAll();
        ctx.currentItem = null;
    }, [ctx, r]);

    // cancel
    const handleCancel = useCallback(() => {
        setErrors("");
        if (r) {
            r.clearAll();
        }
        ctx.currentItem = null;
    }, [ctx, r, setErrors]);

    // delete
    const handleDelete = useCallback(() => {
        setErrors("");
        if (r) {
            ctx.deleteNode(r.prevBlockId);
            r.clearAll();
        }
        ctx.currentItem = null;
    }, [ctx, r, setErrors]);

    // clear(only single view mode)
    const handleClear = useCallback(() => {
        setErrors("");
        if (r) {
            r.clearAll();
            ctx.notifyChanged();
        }
    }, [ctx, r, setErrors]);

    // validate(only single view mode)
    const handleValidate = useCallback(() => {
        setErrors("");
        if (r) {
            const blockIdErr = ctx.validateUniqueId(r);
            if (blockIdErr !== "") {
                setErrors(blockIdErr);
            }
            r.validate();
            if (blockIdErr === "" && !r.hasError) {
                setValidateResultSnack(true);
            }
            ctx.notifyChanged();
        }
    }, [ctx, r, setErrors]);

    return (
        <Slide direction="left" in={r !== null} mountOnEnter unmountOnExit>
            <Grid container direction="row" spacing={1} style={{display: r === null ? "none" : "flex"}}
                  className={classes.container}>
                <Grid item xs={6}>
                    <div className={classes.buttons}>
                        {/*Add*/}
                        <Fab size="medium" color="primary" aria-label="add" onClick={handleSubmit}
                             className={`${classes.baseButton} ${classes.addButton}`}
                             style={{display: isNew && !props.singleView ? "absolute" : "none"}}
                        >
                            <AddIcon/>
                        </Fab>

                        {/*Update*/}
                        <Fab size="medium" color="primary" aria-label="update" onClick={handleSubmit}
                             className={`${classes.baseButton} ${classes.updateButton}`}
                             style={{display: !isNew && !props.singleView ? "absolute" : "none"}}
                        >
                            <UpdateIcon/>
                        </Fab>

                        {/* Delete */}
                        <Fab size="medium" color="secondary" aria-label="delete" onClick={handleDelete}
                             variant={"extended"}
                             className={`${classes.baseButton} ${classes.deleteButton}`}
                             style={{display: !isNew && !props.singleView ? "absolute" : "none"}}
                        >
                            <DeleteIcon/>Delete
                        </Fab>

                        {/* Validate(SingleView) */}
                        <Fab size="medium" color="primary" aria-label="validate" onClick={handleValidate}
                             variant={"extended"}
                             className={`${classes.baseButton} ${classes.validateButton}`}
                             style={{display: props.singleView ? "absolute" : "none"}}
                        >
                            <UpdateIcon/>Check
                        </Fab>
                        <Snackbar
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                            open={validateResultSnack}
                            autoHideDuration={3000}
                            onClose={() => {
                                setValidateResultSnack(false)
                            }}
                        >
                            <Alert elevation={6} variant="filled"
                                   onClose={() => {
                                       setValidateResultSnack(false)
                                   }}
                                   severity="success">
                                Validation Successful!
                            </Alert>
                        </Snackbar>

                        {/* Clear(SingleView) */}
                        <Fab size="medium" color="secondary" aria-label="clear" onClick={handleClear}
                             variant={"extended"}
                             className={`${classes.baseButton} ${classes.clearButton}`}
                             style={{display: props.singleView ? "absolute" : "none"}}
                        >
                            <ClearIcon/>Clear
                        </Fab>
                    </div>
                    <div className={classes.editor}>
                        <ResourceInput ctx={ctx} resource={r} handler={handleChange}
                                       errors={errors} setErrors={setErrors}/>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div className={classes.buttons}>
                        <CopyToClipboard text={props.resource === null ? "" : props.resource.tfFileBody} onCopy={() => {
                            setOpenSnack(true)
                        }}>
                            <Fab size="medium" color="primary" aria-label="copy" variant="extended"
                                 className={`${classes.baseButton} ${classes.copyButton}`}>
                                <FileCopyIcon/>Copy to Clipboard
                            </Fab>
                        </CopyToClipboard>
                        <Fab size="medium" aria-label="close" onClick={handleCancel}
                             className={`${classes.baseButton} ${classes.closeButton}`}
                             style={{display: !props.singleView ? "absolute" : "none"}}
                        >
                            <CloseIcon/>
                        </Fab>
                        <Snackbar
                            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                            open={openSnack}
                            autoHideDuration={3000}
                            onClose={() => {
                                setOpenSnack(false)
                            }}
                            message="Copied to clipboard"
                        />
                    </div>
                    <SyntaxHighlighter style={dark} language={"hcl"}
                                       codeTagProps={{
                                           style: {
                                               display: "block",
                                               paddingTop: "25px",
                                               fontSize: "large",
                                           }
                                       }}
                                       customStyle={{
                                           marginTop: "10px",
                                           marginLeft: "10px",
                                           overflow: "auto",
                                           height: "100%",
                                           padding: 0,
                                           paddingLeft: 40,
                                       }}>

                        {props.resource === null ? "" : props.resource.tfFileBody}

                    </SyntaxHighlighter>
                </Grid>
            </Grid>
        </Slide>
    )
};

export default ResourceEditor;