import React from 'react';
import {AppContext} from "./AppContext";
import {createMuiTheme, Grid, MuiThemeProvider, Theme} from "@material-ui/core";
import TopBar from "./TopBar";
import GraphCanvas from "./GraphCanvas";
import CodeBlock from "./CodeBlock";
import Palette from "./Pallete";
import ResourceEditor from "./editor/ResourceEditor";
import makeStyles from "@material-ui/core/styles/makeStyles";

const theme = createMuiTheme({
    palette: {
        type: 'light',
    },
});

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        height: 800,
        width: "98%",
        marginLeft: 10,
    },
}));

type Props = {
    ctx: AppContext
}

const Editor: React.FC<Props> = (props) => {
    const ctx = props.ctx;
    const classes = useStyles();

    return (
        <MuiThemeProvider theme={theme}>
            <Grid container>
                <Grid item xs={12}>
                    <TopBar/>
                </Grid>
                <Grid container>
                    <Grid item xs={ctx.sideBarSize}>
                        <Palette ctx={ctx}/>
                    </Grid>
                    <Grid item xs={ctx.canvasSize}>
                        <ResourceEditor ctx={ctx} resource={ctx.currentItem}/>
                        <div className={classes.container}>
                            <GraphCanvas ctx={ctx} hidden={!ctx.currentItemIsEmpty}/>
                            <CodeBlock tfFileBody={ctx.tfFileBody} hidden={!ctx.currentItemIsEmpty}/>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </MuiThemeProvider>
    );
}

export default Editor;

