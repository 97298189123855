import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import Input from "@material-ui/core/Input";
import {TerraformSchemaAllowValues} from "../../lib/schema";
import {Autocomplete} from "@material-ui/lab";
import ErrorTooltip from "./ErrorTooltip";

type Props = {
    fieldName: string
    required?: boolean
    placeholder?: string
    value: string | number
    error: boolean
    helperText?: string
    onChange?: (
        event: React.ChangeEvent<{}>,
        value: string,
        reason: 'input' | 'reset' | 'clear',
    ) => void;
    allowValues: TerraformSchemaAllowValues
    //children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
    inputs: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        //backgroundColor: "rgba(224,224,224,0.8)",
        backgroundColor: "white",
        minWidth: "100px",
        borderRadius: 5,
    },
    root: {
        display: "inline-flex",
    },
    base: {
        padding: "3px 0 3px",
    },
    error: {
        border: "2px solid red",
        borderRadius: 5,
        backgroundColor: red[50],
    },
    tooltip: {
        backgroundColor: red[500],
        color: "white",
        fontSize: 15,
    },
    arrow: {
        backgroundColor: red[500],
    }
}));

const AutocompleteInput: React.FC<Props> = (props) => {
    const classes = useStyles();
    const placeholder = props.placeholder ? props.placeholder : "";
    if (props.allowValues === null) {
        console.log("[WARN] allowValues is null");
        return <></>
    }

    let maxLen = Math.max(...props.allowValues.map(v => v.text ? v.text.length : 0));
    if (props.value.toString().length > maxLen) {
        maxLen = props.value.toString().length;
    }

    return <ErrorTooltip title={props.helperText} open={props.error}>
        <Autocomplete
            id={`${props.fieldName}-id`}
            freeSolo={false}
            className={classes.inputs}
            classes={{root: classes.root}}
            options={props.allowValues.map((v => v.text))}
            style={{width: `${maxLen-6}ch`}} // TODO サイズ調整
            disableClearable={false}
            autoSelect={false}
            renderInput={params => (
                <Input
                    {...params.InputProps}
                    id={params.id}
                    disabled={params.disabled}
                    classes={{error: classes.error, input: classes.base}}
                    placeholder={`${props.required ? "* " : ""}${placeholder}`}
                    error={props.error}
                    disableUnderline={true}
                    value={props.value}
                    inputProps={params.inputProps}
                    style={
                        {width:"100%"}
                    }
                />
            )}
            onInputChange={(e, value, reason) => {
                if (props.onChange) {
                    props.onChange(e, value, reason);
                }
            }}
            value={props.value.toString()}
        />
    </ErrorTooltip>
};

export default AutocompleteInput;