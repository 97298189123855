import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {TerraformSchemaAllowValues} from "../../lib/schema";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ErrorTooltip from "./ErrorTooltip";

type Props = {
    fieldName: string
    required?: boolean
    value: string[]
    values: TerraformSchemaAllowValues;
    error: boolean
    helperText?: string
    onChange?:  (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    row: {
        backgroundColor: "white",
        color: "black",
        textShadow: "black 0 0 0",
        paddingLeft: theme.spacing(2),
        borderRadius: 5,
    },
    checkboxRoot: {
        padding: "3px 9px",
    },
    error: {
        border: "2px solid red",
        borderRadius: 5,
        backgroundColor: red[50],
    },
    tooltip: {
        backgroundColor: red[500],
        color: "white",
        fontSize: 15,
    },
    arrow: {
        backgroundColor: red[500],
    }
}));

const CheckListInput: React.FC<Props> = (props) => {
    const classes = useStyles();
    const values = props.values;

    return (
        <ErrorTooltip title={props.helperText} open={props.error}>
            <FormGroup row className={classes.row}>
                {values && values.map(v => (
                    <FormControlLabel
                        key={v.value}
                        control={
                            <Checkbox
                                checked={props.value.includes(v.value)}
                                className={classes.checkboxRoot}
                                onChange={(e,checked) => {
                                    if (props.onChange) {
                                        props.onChange(e,checked);
                                    }
                                }}
                            value={v.value}/>
                        }
                        label={v.text}
                    />
                ))}
            </FormGroup>
        </ErrorTooltip>
    )
};

export default CheckListInput;