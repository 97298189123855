import React from "react";
import Token  from "./Token";

type Props = {
    children: React.ReactNodeArray
}

const Tokens: React.FC<Props> = (props) => {
    const tokens = props.children.map((c,i) => {
        if (c) {
            const space = i === props.children.length - 1 ? "" : " ";
            return <div key={i}>
                {c}<Token>{space}</Token>
            </div>
        }
        return undefined
    })
    return <>
        {tokens}
    </>
};

export default Tokens;