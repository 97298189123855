import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {red} from "@material-ui/core/colors";

type Props = {
    open: boolean
    title?: string
    children: React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: red[500],
        color: "white",
        fontSize: 15,
    },
    arrow: {
        color: red[500],
    }
}));

const ErrorTooltip: React.FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <Tooltip arrow title={props.title || ""} open={props.open} placement={"right"}
                 classes={{tooltip: classes.tooltip, arrow: classes.arrow}}>
            {props.children}
        </Tooltip>
    )
};

export default ErrorTooltip;