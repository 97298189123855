import React from "react";
import Item from "./Item";
import makeStyles from "@material-ui/core/styles/makeStyles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import {createStyles, Theme} from "@material-ui/core";
import {AppContext} from "./AppContext";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import {ResourceCategory} from "../lib/resource";
import ListItemIcon from "@material-ui/core/ListItemIcon";

type Props = {
    className?: string
    ctx: AppContext
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            marginRight: theme.spacing(5),
        },
        categories: {
            paddingLeft: theme.spacing(3),
        },
        opened: {
            borderRight: "3px solid navy",
        },
    }),
);


const Palette: React.FC<Props> = props => {
    const classes = useStyles();

    const handleClick = (c: ResourceCategory) => () => {
        c.open = !c.open;
        props.ctx.notifyChanged();
    };

    const rp = props.ctx.resourceProvider;
    const resources = rp.resources
        .map(c => {
            return [
                <ListItem button onClick={handleClick(c)} key={`category-rs-${c.name}-header`}
                          className={classes.categories} selected={c.open} classes={{selected: classes.opened}}>
                    <ListItemIcon>{React.createElement(c.icon, {color: "primary"}, null)}</ListItemIcon>
                    <ListItemText primary={c.displayName}/>
                    {c.open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                ,
                <Collapse in={c.open} timeout="auto" unmountOnExit key={`category-rs-${c.name}-body`}>
                    <List component="div" disablePadding>
                        {c.resources.map(r =>
                            <Item key={`rs-${r.name}`} ctx={props.ctx} resource={r}/>
                        )}
                    </List>
                </Collapse>
                ,
            ]
        })
        .concat();

    const dataSources = rp.dataSources
        .map(c => {
            return [
                <ListItem button onClick={handleClick(c)} key={`category-data-${c.name}-header`}
                          className={classes.categories} selected={c.open} classes={{selected: classes.opened}}>
                    <ListItemIcon>{React.createElement(c.icon, {color: "primary"}, null)}</ListItemIcon>
                    <ListItemText primary={c.displayName}/>
                    {c.open ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                ,
                <Collapse in={c.open} timeout="auto" unmountOnExit key={`category-data-${c.name}-body`}>
                    <List component="div" disablePadding>
                        {c.resources.map(r =>
                            <Item key={`data-${r.name}`} ctx={props.ctx} resource={r}/>
                        )}
                    </List>
                </Collapse>
                ,
            ]
        })
        .concat();

    return (
        <div className={classes.root}>
            <List className={classes.root}
                  subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                          リソース
                      </ListSubheader>
                  }
            >
                <Divider variant="inset" component="li"/>
                {resources}
            </List>
            <List className={classes.root}
                  subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                          データソース
                      </ListSubheader>
                  }
            >
                <Divider variant="inset" component="li"/>
                {dataSources}
            </List>
        </div>
    );
};

export default Palette;
