import React, {ReactNode} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import Select from "@material-ui/core/Select";
import {TerraformSchemaAllowValues} from "../../lib/schema";
import MenuItem from "@material-ui/core/MenuItem";
import ErrorTooltip from "./ErrorTooltip";

type Props = {
    fieldName: string
    required?: boolean
    value: string | number
    values: TerraformSchemaAllowValues;
    error: boolean
    helperText?: string
    placeholder ?:string
    onChange?: (
        event: React.ChangeEvent<{ name?: string; value: unknown }>,
        child: React.ReactNode,
    ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    select: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        //backgroundColor: "rgba(224,224,224,0.8)",
        minWidth: "100px",
        borderRadius: 5,
        backgroundColor: "white",
    },
    selectErorr: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        //backgroundColor: "rgba(224,224,224,0.8)",
        minWidth: "100px",
        borderRadius: 5,
        border: "2px solid red",
        backgroundColor: red[50],
    },
    base: {
        padding: "3px 0 3px",
        textShadow: "black 0 0 0",
    },
    normal: {},
    error: {
        border: "2px solid red",
        borderRadius: 5,
        backgroundColor: red[50],
    },
    tooltip: {
        backgroundColor: red[500],
        color: "white",
        fontSize: 15,
    },
    placeholder: {
        color: "#aaa"
    }
}));

const SelectInput: React.FC<Props> = (props) => {
    const classes = useStyles();

    return (
        <ErrorTooltip title={props.helperText} open={props.error}>
            <Select
                id={`${props.fieldName}-id`}
                autoWidth={true}
                className={props.error ? classes.selectErorr: classes.select}
                onChange={(e, child) => {
                    if (props.onChange) {
                        props.onChange(e, child)
                    }
                }}
                inputProps={{classes: {select: classes.base}}}
                value={props.value}
                disableUnderline={true}
                displayEmpty={true}
                renderValue={(v):ReactNode => {
                    if (!v || (typeof v !== "string" && typeof v !== "number")) {
                        return <div className={classes.placeholder}>{props.placeholder}</div>
                    }
                    return <div>{v}</div>
                }}
            >
                <MenuItem key={"placeholder"} disabled>{props.placeholder}</MenuItem>
                {!props.required &&
                    <MenuItem key={"empty"} value={""}>(default)</MenuItem>
                }
                {(props.values) && props.values.map(v => (
                    <MenuItem key={v.value} value={v.value}>{v.text}</MenuItem>
                ))}
            </Select>
        </ErrorTooltip>
    )
};

export default SelectInput;