import React, {useState} from "react";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {dark} from "react-syntax-highlighter/dist/esm/styles/prism";
import {Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Snackbar from "@material-ui/core/Snackbar";
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Fab from "@material-ui/core/Fab";

type Props = {
    hidden: boolean
    tfFileBody: string
}


const useStyles = makeStyles((theme: Theme) => ({
    buttons: {
        position: "relative",
    },
    baseButton: {
        marginRight: theme.spacing(1),
    },
    copyButton: {
        position: "absolute",
        top: 18,
        right: 10,
    },
}));


const CodeBlock: React.FC<Props> = (props) => {
    const classes = useStyles();
    const [openSnack, setOpenSnack] = useState(false);

    return (
        <div style={{display: props.hidden ? "none": "inherit"}}>
            <div className={classes.buttons}>
                <CopyToClipboard text={props.hidden ? "" : props.tfFileBody} onCopy={()=>{setOpenSnack(true)}}>
                    <Fab size="medium" color="primary" aria-label="copy" variant="extended"
                         className={`${classes.baseButton} ${classes.copyButton}`}>
                        <FileCopyIcon/>Copy to Clipboard
                    </Fab>
                </CopyToClipboard>
                <Snackbar
                    anchorOrigin={{ vertical:'top', horizontal:'center'}}
                    open={openSnack}
                    autoHideDuration={3000}
                    onClose={()=>{setOpenSnack(false)}}
                    message="Copied to clipboard"
                />
            </div>
            <SyntaxHighlighter style={dark} customStyle={{height: "400px"}}
                               language={"hcl"}>
                {props.tfFileBody}
            </SyntaxHighlighter>
        </div>
    )
};

export default CodeBlock;
