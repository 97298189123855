import {
    calcNameAdjustSpaceLength,
    MergedTerraformSchemaArgument,
    MergedTerraformSchemaArgumentBlock,
    MergedTerraformSchemaBlock
} from "./schema";
import {TerraformSchemaAttributeBlock} from "./terraformSchema";
import {OverridableComponent} from "@material-ui/core/OverridableComponent";
import {SvgIconTypeMap} from "@material-ui/core/SvgIcon/SvgIcon";

export let IndentSize = 2;

/**
 * Terraform Providerから生成されたスキーマ情報とコード生成で対応できない部分に対する独自の定義を組み合わせ、
 * アプリケーションでの利用に適した形で提供する
 */
export interface ResourceProvider {
    version: string;
    provider: Resource;
    resources: ResourceCategory[];
    dataSources: ResourceCategory[];
}

export interface IResourceCategory {
    name: string;
    displayName: string;
    resources: Resource[];
    open: boolean;
    icon: OverridableComponent<SvgIconTypeMap>;
}

export class ResourceCategory implements IResourceCategory {
    name: string;
    displayName: string;
    resources: Resource[];
    icon: OverridableComponent<SvgIconTypeMap>;
    onOpenChanged?: (c: ResourceCategory) => void;

    constructor(init: IResourceCategory) {
        this.name = init.name;
        this.displayName = init.displayName;
        this.resources = init.resources;
        this._open = init.open;
        this.icon = init.icon;
    }

    private _open: boolean;
    get open(): boolean {
        return this._open;
    }

    set open(o: boolean) {
        this._open = o;
        if (this.onOpenChanged) {
            this.onOpenChanged(this);
        }
    }
}

interface IResource {
    blockId: string;
    type: "provider" | "resource" | "data";
    category: string;
    name: string; // e.g. server
    fullName: string; // e.g. sakuracloud_server
    displayName: string; // e.g. サーバ
    image: string; // e.g. server.png
    order: number;
    ignore: boolean;
    blocks: MergedTerraformSchemaBlock
}

/**
 * プロバイダー/リソース/データソースの各フィールドの入力値+名称/属性/スキーマなどのメタデータ
 * パレットにバインドされる。
 * NodeはResourceのコピーから作成される。
 */
export class Resource implements IResource {
    blockId: string;
    prevBlockId: string;
    type: "provider" | "resource" | "data";
    category: string;
    name: string;
    fullName: string;
    displayName: string;
    image: string;
    order: number;
    ignore: boolean;
    blocks: MergedTerraformSchemaBlock;
    onWorkBench: boolean;

    private _open: boolean;
    get open() {
        return this._open;
    }

    set open(v: boolean) {
        this._open = v;
        if (this.openStateChanged) {
            this.openStateChanged(this);
        }
    };

    openStateChanged?: (r: Resource) => void;

    constructor(init: IResource) {
        this.blockId = init.blockId;
        this.prevBlockId = init.blockId;
        this.type = init.type;
        this.category = init.category;
        this.name = init.name;
        this.fullName = init.fullName;
        this.displayName = init.displayName;
        this.image = init.image;
        this.order = init.order;
        this.ignore = init.ignore;
        this.blocks = init.blocks;
        this.onWorkBench = false;
        this._open = false;

        this.completeAll();
    }

    private completeAll(): void {
        this.blocks.arguments.forEach(arg => {
            arg.completeValues(this.blocks.argumentBlocks);
        });
    }

    get sortedArguments(): MergedTerraformSchemaArgument[] {
        return this.blocks.arguments.sort((a, b) => {
            if (a.order === b.order) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }
            return a.order - b.order;
        })
    }

    get referenceResourceIds(): string[] {
        return this.blocks.referenceResourceIds;
    }

    get isNew(): boolean {
        return this.prevBlockId === ""
    }

    clone(): Resource {
        const r = new Resource({
            blockId: this.blockId,
            type: this.type,
            category: this.category,
            name: this.name,
            fullName: this.fullName,
            displayName: this.displayName,
            image: this.image,
            order: this.order,
            ignore: this.ignore,
            blocks: this.blocks,
        });
        r.prevBlockId = this.prevBlockId;
        r._open = this._open;
        r.blocks = this.blocks.clone();
        r.onWorkBench = this.onWorkBench;
        return r
    }

    argumentBlockByName(name: string): MergedTerraformSchemaArgumentBlock | undefined {
        const b = this.blocks.argumentBlocks.find(b => b.name === name);
        if (b) {
            return b.clone();
        }
        return undefined
    }

    attributeBlockByName(name: string): TerraformSchemaAttributeBlock | undefined {
        const b = this.blocks.attributeBlocks.find(b => b.name === name);
        if (b) {
            return JSON.parse(JSON.stringify(b));
        }
        return undefined
    }


    clearAll() {
        this.blockId = "";
        this.prevBlockId = "";
        this.onWorkBench = false;
        this.blocks.arguments.forEach(a => {
            a.resetValue();
            a.completeValues(this.blocks.argumentBlocks);
        });
    }

    validate() {
        this.blocks.arguments.forEach(a => a.validate())
    }

    get hasError(): boolean {
        return this.blocks.arguments.some(a => a.hasError);
    }

    get fieldNames(): string[] {
        return [
            "id",
        ];
    }

    // tf file
    /**
     * tfファイル上でのノードのIDを表す
     *
     * e.g. sakuracloud_disk.disk01 または data.sakuracloud_archive.centos
     */
    get tfNodeId(): string {
        const pref = this.type === "data" ? "data." : "";
        return `${pref}${this.fullName}.${this.blockId}`;
    }

    get tfNodeFieldNames(): string[] {
        return this.fieldNames.map(n => [this.tfNodeId, n].join("."))
    }

    get tfFileBody(): string {
        return `${this.type} "${this.fullName}" "${this.blockId}" {
${this.tfFileBodyBlocks()}
}
`
    }

    private tfFileBodyBlocks(): string {
        const adjustLen = calcNameAdjustSpaceLength(this.blocks.arguments);
        return this.sortedArguments
            .map(arg => {
                return this.tfFileArgumentLine(arg, 0, adjustLen);
            })
            .map(s => s.replace(/^\s+$/m, ""))
            .filter(s => s !== "")
            .join("\n");
    }

    private tfFileArgumentLine(arg: MergedTerraformSchemaArgument, indent: number, keyValueAdjustLen: number): string {
        if (arg.valueIsEmpty()) {
            return ""
        }
        switch (arg.type) {
            case "string":
                if (arg.referenceSourcePatterns.length > 0 && arg.allowValues && arg.allowValues.length > 0 ) { // TODO completion対象かの判定を改善すべし
                    return this.formatLine(arg.name, `${arg.value as string}`, keyValueAdjustLen);
                } else {
                    return this.formatLine(arg.name, `"${arg.value as string}"`, keyValueAdjustLen);
                }
            case "boolean" :
                return this.formatLine(arg.name, `${arg.value as boolean}`, keyValueAdjustLen);
            case "number" :
                return this.formatLine(arg.name, `${arg.value as number}`, keyValueAdjustLen);
            case "stringList" :
                if ((arg.value as string[]).length === 0) {
                    return ""
                }
                return this.formatLine(arg.name, `[${(arg.value as string[]).map(s => {
                    const quote = arg.referenceSourcePatterns.length > 0 && arg.allowValues && arg.allowValues.length > 0 ? "" : `"`;  // TODO completion対象かの判定を改善すべし
                    return `${quote}${s}${quote}`
                }).join(", ")}]`, keyValueAdjustLen);
            case "map":
                const values = arg.value as Map<string,string>;
                return this.formatMap(arg.name, values, indent);
            case "block":
                return (arg.value as MergedTerraformSchemaArgumentBlock[])
                    .map(blockArg => this.formatBlock(blockArg, indent))
                    .join("\n");
        }

    }

    private formatLine(name: string, value: string, keyValueAdjustLen: number): string {
        const keyValue = `${name}${"".padStart(keyValueAdjustLen - name.length, " ")} = ${value}`;
        return `${"".padStart(IndentSize, " ")}${keyValue}`;
    }

    private formatBlock(blockArg: MergedTerraformSchemaArgumentBlock, indent: number): string {
        const adjustLen = calcNameAdjustSpaceLength(blockArg.arguments);
        const lines = [
            `${blockArg.name} {`,
            ...blockArg.arguments.map(arg => {
                if (arg.type === "block") {
                    return this.tfFileArgumentLine(arg, indent, adjustLen).split("\n");
                }
                if (arg.type === "map") {
                    return this.tfFileArgumentLine(arg, indent + 1, adjustLen).split("\n");
                }
                return this.tfFileArgumentLine(arg, indent + 1, adjustLen)
            }).flat().filter(s => s !== ""),
            `}`,
        ];
        return lines
            .map(s => "".padStart((indent + 1) * IndentSize, " ") + s)
            .join("\n");
    }

    private formatMap(name: string, values: Map<string,string>, indent: number): string {
        if (indent === 0) {
            indent = 1;
        }
        const keys = Array.from(values.keys());
        const adjustLen = Math.max(...keys.map(a => a.length));
        const lines = [
            "".padStart((indent) * IndentSize, " ")  + `${name} = {`,
            ...keys.map(key => {
                const v = values.get(key);
                if (v) {
                    return this.formatLine(key, `"${v}"`, adjustLen);
                }
                return "";
            }).flat().filter(s => s !== "").map(l => "".padStart((indent)*IndentSize, " ") + l),
            "".padStart((indent) * IndentSize, " ")  + `}`,

        ];
        return lines.join("\n");
    }

}

