import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import Token from "./Token";

type Props = {
    children?: React.ReactNode
    indent?: number
}

const useStyles = makeStyles((theme: Theme) => ({
    line: {
        display: "block",
        paddingLeft: theme.spacing(5),
    },
    code: {
        display: "flex",
        lineHeight: "40px",
        alignItems: "center",
    },
}));

const Line: React.FC<Props> = (props) => {
    const classes = useStyles();
    const indent = props.indent && props.indent > 0 ? " ".repeat(props.indent) : "";
    return (
        <div className={classes.line}>
            <code className={classes.code}><Token>{indent}</Token>{props.children}</code>
        </div>
    )
};

export default Line;