import {Workbench} from "../lib/workbench";
import {ElementDefinition, Stylesheet} from "cytoscape";
import {Resource, ResourceProvider} from "../lib/resource";
import {GridSize} from "@material-ui/core";

export class AppContext {
    private workbench: Workbench;

    updateContext?: (ctx: AppContext) => void;

    set resourceOpenStateHandler(handler: (r: Resource) => void) {
        this.workbench.resourceOpenStateHandler = handler;
    }

    constructor(init ?: AppContext) {
        if (init) {
            this.workbench = init.workbench;
            this.updateContext = init.updateContext;
            this._sideBarSize = init._sideBarSize;
        } else {
            this.workbench = new Workbench();
            this._sideBarSize = 2;
        }
    }

    get currentItemIsEmpty(): boolean {
        return this.workbench.currentItemIsEmpty;
    }

    get currentItem(): Resource | null {
        return this.workbench.currentItem;
    }

    set currentItem(r: Resource | null) {
        if (r !== null && r.blockId === "") {
            r.blockId = this.getDefaultBlockId(r);
        }
        this.workbench.currentItem = r;
        this.notifyChanged();
    }

    private _sideBarSize: GridSize;
    get sideBarSize(): GridSize {
        return this._sideBarSize;
    }

    set sideBarSize(size: GridSize) {
        this._sideBarSize = size;
        this.notifyChanged();
    }

    get canvasSize(): GridSize {
        if (typeof (this.sideBarSize) === 'number') {
            return 12 - this.sideBarSize as GridSize;
        }
        return 'auto';
    }

    get elementStyles(): Stylesheet[] {
        const schemas = [
            ...this.workbench.resourceProvider.resources.map(c => c.resources).flat(),
            ...this.workbench.resourceProvider.dataSources.map(c => c.resources).flat(),
        ];
        const uniq = new Map<string, Resource>();
        schemas.map(r => uniq.has(r.name) ? uniq : uniq.set(r.name, r));
        return Array.from(uniq.values()).map(r => {
            return {selector: `.${r.name}`, style: {"background-image": r.image}}
        })
    }

    get graphElements(): ElementDefinition[] {
        return [
            ...this.workbench.nodes.map((v) => {
                return {data: {id: v.blockId, label: v.blockId}, classes: v.name}
            },),
            ...this.workbench.edges.map((v) => {
                return {data: {source: v.source, target: v.target}, classes: "taxi"}
            },),
        ]
    }

    get tfFileBody(): string {
        return this.workbench.tfFileBody;
    }

    get resourceProvider(): ResourceProvider {
        return this.workbench.resourceProvider;
    }

    getDefaultBlockId(r: Resource): string {
        return this.workbench.getDefaultBlockId(r);
    }

    addNode(r: Resource) {
        this.workbench.addNode(r);
        this.notifyChanged();
    }

    deleteNode(...ids: string[]) {
        ids.map(id => this.workbench.deleteNode(id));
        this.notifyChanged();
    }

    deleteAll() {
        this.workbench.deleteAll();
        this.notifyChanged();
    }

    notifyChanged() {
        if (this.updateContext) {
            this.updateContext(new AppContext(this));
        }
    }

    setViewPort(arg: { height: number, width: number }) {
        this.workbench.viewport = arg;
    }

    propagateCurrentNodeValue(nodeId: string, clear: boolean) {
        this.workbench.setCurrentWorkItem(nodeId, clear);
        this.notifyChanged();
    }

    validateUniqueId(r: Resource): string {
        if (r.blockId === "") {
            return "required"
        }
        if (this.workbench.nodes.some(n => n.blockId !== r.prevBlockId && n.blockId === r.blockId)) {
            return `'${r.blockId}' is duplicated`;
        }
        return "";
    }

    setCurrentEditTarget(type: string, resourceName: string): boolean {
        if (type !== "data" && type !== "resource") {
            return false;
        }
        const r = this.workbench.allResources.find(r => r.type === type && r.name === resourceName);
        if (!r) {
            return false;
        }
        this.workbench.currentItem = r;
        return true;
    }
}

