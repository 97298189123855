import {Edge} from "./edge";
import {SakuraCloudSchema} from "./sakuracloud";
import {LoadResourceProvider} from "./schema";
import {Resource, ResourceCategory, ResourceProvider} from "./resource";

export class Workbench {
    viewport: {
        height: number;
        width: number;
    };
    nodes: Resource[];
    edges: Edge[];
    resourceProvider: ResourceProvider;

    set resourceOpenStateHandler(handler: (r: Resource) => void) {
        this.allResources.map(r => r.openStateChanged = handler);
    }

    constructor() {
        this.nodes = [];
        this.edges = [];
        this.viewport = {height: 0, width: 0};
        this.resourceProvider = LoadResourceProvider(SakuraCloudSchema);
        this._currentItem = null;
        const handler = (c: ResourceCategory) => {
            this.allCategories.filter(cat => cat !== c).forEach(cat => {
                if (c.open) {
                    cat.open = false
                }
            });
        };
        this.allCategories.map(cat => cat.onOpenChanged = handler);
    }

    get allResources(): Resource[] {
        return this.allCategories.map(c => c.resources).flat();
    }

    get allCategories(): ResourceCategory[] {
        return [...this.resourceProvider.dataSources, ...this.resourceProvider.resources];
    }

    getDefaultBlockId(r: Resource): string {
        const nextSuffix = (this.nodes.filter(n => r.fullName === n.fullName).length + 1)
            .toString()
            .padStart(2,"0");
        return `${r.name}${nextSuffix}`;
    }

    addNode(r: Resource): void {
        if (r.blockId === "") {
            r.blockId = `${r.name}${this.nodes.filter(n => n.category === r.category).length + 1}`;
        }
        if (r.onWorkBench) {
            this.deleteNode(r.prevBlockId);
        }
        r.prevBlockId = r.blockId;
        r.onWorkBench = true;

        this.nodes.push(r);
        this.calcNodeDependencies();
        this.calcEdges();
    }

    deleteNode(blockId: string): void {
        if (blockId === "") {
            return
        }
        this.nodes = this.nodes.filter(v => v.blockId !== blockId);
        this.calcNodeDependencies();
        this.calcEdges();
    }

    deleteAll(): void {
        this.nodes = [];
        this.edges = [];
        this.calcNodeDependencies();
    }

    tfNodeFieldNames(...ignoreBlockIds: string[]): {id:string,name:string}[] {
        const targets = this.nodes.filter(n => !ignoreBlockIds.includes(n.blockId));
        if (targets) {
            return targets.map(r => {
                return r.tfNodeFieldNames.map(v => {
                    return {id: r.blockId, name: v}
                })
            }).flat()
        }
        return [];
    }

    get tfFileBody(): string {
        const blocks = this.nodes
            .filter(n => n.tfFileBody !== "")
            .map(n => n.tfFileBody);
        blocks.unshift(this.tfProviderBlock);
        return blocks.join("\n");
    }

    private get tfProviderBlock(): string {
        return `terraform {
  required_providers {
    sakuracloud = {
      source = "sacloud/sakuracloud"
      version = "${this.resourceProvider.version}"
    }
  }
}
`
    }

    get currentItemIsEmpty(): boolean {
        return this.currentItem === null;
    }

    private _currentItem: Resource | null;
    get currentItem(): Resource | null {
        return this._currentItem;
    }

    set currentItem(r: Resource | null) {
        if (this._currentItem) {
            this._currentItem.open = false;
        }
        if (r) {
            r.open = true;
        }
        this._currentItem = r;
    }

    setCurrentWorkItem(blockId: string, clear: boolean) {
        const resource = this.nodes.find(n => n.blockId === blockId);
        if (!resource) {
            return;
        }
        let categories: ResourceCategory[];
        switch (resource.type) {
            case "resource":
                categories = this.resourceProvider.resources;
                break;
            case "data":
                categories = this.resourceProvider.dataSources;
                break;
            default:
                return;
        }
        const cat = categories.find(r => r.name === resource.category);
        if (cat) {
            const r = cat.resources.find(r => r.fullName === resource.fullName);
            if (r) {
                r.blockId = blockId;
                r.prevBlockId = resource.prevBlockId;
                r.blocks = resource.blocks.clone();
                if (clear) {
                    r.onWorkBench = false;
                    r.clearAll();
                    cat.open = false;
                    this.currentItem = null;
                } else {
                    r.onWorkBench = true;
                    cat.open = true;
                    this.currentItem = r;
                }
            }
        }
    }

    private calcNodeDependencies() {
        this.calcReferenceCandidates();
    }

    private calcEdges() {
        this.edges = [];
        this.nodes.forEach(n => {
            if (n.referenceResourceIds.length > 0) {
                this.edges.push(...n.referenceResourceIds.map(id => new Edge({
                    id: `${n.blocks}-${id}`,
                    source: n.blockId,
                    target: id,
                })))
            }
        })
    }

    private calcReferenceCandidates() {
        this.allResources.forEach(r => {
            r.blocks.arguments.forEach(a => {
                const candidates = a.referenceSourcePatterns.map(ref => {
                    const own = r.prevBlockId !== "" ? r.prevBlockId : r.blockId;
                    return this.tfNodeFieldNames(own).filter(f => ref.test(f.name))
                }).flat();
                if (candidates.length > 0) {
                    a.allowValues = candidates.map(c => {
                        return {value: c.id , text: c.name}
                    })
                }
            })
        });
    }
}

