import {
    TerraformProvider,
    TerraformSchema,
    TerraformSchemaArgument,
    TerraformSchemaArgumentBlock,
    TerraformSchemaAttribute,
    TerraformSchemaAttributeBlock,
    TerraformSchemaBlock,
    TerraformValueType
} from "./terraformSchema";
import {argumentBlockDef, argumentDef, categoryDef, dataSourceDef, resourceDef} from "./zouenDef";
import {Resource, ResourceCategory, ResourceProvider} from "./resource";

export function LoadResourceProvider(provider: TerraformProvider): ResourceProvider {
    return {
        version: provider.version,
        provider: new Resource({
            blockId: "sakuracloud",
            type: "provider",
            category: "provider",
            name: provider.provider.name,
            fullName: provider.provider.name,
            displayName: provider.provider.displayName,
            image: "",
            order: 99,
            ignore: false,
            blocks: new MergedTerraformSchemaBlock(provider.provider.schema, undefined),
        }),
        resources: categoryDef
            .sort((a, b) => a.order - b.order)
            .filter((category) => !category.ignore)
            .map(category => {
                return new ResourceCategory({
                    name: category.name,
                    displayName: category.displayName,
                    open: false,
                    icon: category.icon,
                    resources: provider.resources
                        .map(r => getMergedResourceSchema(r))
                        .filter(r => r.category === category.name && !r.ignore)
                        .sort((a, b) => a.order - b.order)
                        .map(r => {
                            return new Resource({
                                blockId: "",
                                type: r.type,
                                category: r.category,
                                name: r.name.replace(`${provider.provider.name}_`, ""),
                                fullName: r.name,
                                displayName: r.displayName,
                                image: r.image === "" ? `${ImagePrefix}/default.png` : `${ImagePrefix}/${provider.provider.name}/${r.image}`,
                                order: r.order,
                                ignore: r.ignore,
                                blocks: r.blocks,
                            });
                        }),
                });
            })
            .filter(v => v.resources.length > 0),
        dataSources: categoryDef
            .sort((a, b) => a.order - b.order)
            .filter((category) => !category.ignore)
            .map(category => {
                return new ResourceCategory({
                    name: category.name,
                    displayName: category.displayName,
                    open: false,
                    icon: category.icon,
                    resources: provider.dataSources
                        .map(r => getMergedDataSourceSchema(r))
                        .filter(r => r.category === category.name && !r.ignore)
                        .sort((a, b) => a.order - b.order)
                        .map(r => {
                            return new Resource({
                                blockId: "",
                                type: r.type,
                                category: r.category,
                                name: r.name.replace(`${provider.provider.name}_`, ""),
                                fullName: r.name,
                                displayName: r.displayName,
                                image: r.image === "" ? `${ImagePrefix}/default.png` : `${ImagePrefix}/${provider.provider.name}/${r.image}`,
                                order: r.order,
                                ignore: r.ignore,
                                blocks: r.blocks,
                            });
                        }),
                });
            })
            .filter(v => v.resources.length > 0),
    }
}

const ImagePrefix = "/images";

export interface MergedTerraformSchema {
    id: string
    name: string;
    type: "provider" | "resource" | "data";
    displayName: string;
    category: string;
    image: string;
    order: number;
    ignore: boolean;
    blocks: MergedTerraformSchemaBlock
}

export const calcNameAdjustSpaceLength = (args: MergedTerraformSchemaArgument[], includeEmpty?: boolean) => {
    const targets = includeEmpty ? args : args.filter(a => !a.valueIsEmpty());
    return Math.max(...targets.map(a => a.name.length));
}

export class MergedTerraformSchemaBlock {
    private readonly _init: TerraformSchemaBlock;
    private readonly _def: resourceDef | undefined;

    arguments: MergedTerraformSchemaArgument[]
    attributes: TerraformSchemaAttribute[]
    argumentBlocks: MergedTerraformSchemaArgumentBlock[]
    attributeBlocks: TerraformSchemaAttributeBlock[]

    constructor(init: TerraformSchemaBlock, def: resourceDef | undefined) {
        this._init = init;
        this._def = def;

        this.arguments = init.arguments.map(a => {
            const d = def && def.arguments ? def.arguments.find(d => d.name === a.name) : undefined;
            return new MergedTerraformSchemaArgument(a, d)
        }).sort((a, b) => a.order - b.order);
        this.attributes = init.attributes;
        this.argumentBlocks = init.argumentBlocks.map(a => {
            const d = def && def.argumentBlocks ? def.argumentBlocks.find(d => d.name === a.name) : undefined;
            return new MergedTerraformSchemaArgumentBlock(a, d)
        });
        this.attributeBlocks = init.attributeBlocks;
    }

    clone(): MergedTerraformSchemaBlock {
        const n = new MergedTerraformSchemaBlock(this._init, this._def);
        n.arguments = this.arguments.map(a => a.clone()).sort((a, b) => a.order - b.order);
        n.attributes = JSON.parse(JSON.stringify(this.attributes));
        n.argumentBlocks = this.argumentBlocks.map(a => a.clone());
        n.attributeBlocks = JSON.parse(JSON.stringify(this.attributeBlocks));
        return n;
    }

    get referenceResourceIds(): string[] {
        return [
            ...this.arguments
                .filter(v => v.referenceResourceIds.length > 0)
                .map(v => v.referenceResourceIds)
                .flat()
                .filter(v => v !== ""),
            ...this.argumentBlocks.map(v => v.referenceResourceIds).filter(v => v.length > 0).flat(),
        ];
    }
}

export type TerraformSchemaArgumentValue =
    boolean
    | string
    | number
    | string[]
    | Map<string,string>
    | MergedTerraformSchemaArgumentBlock[];

export type TerraformSchemaAllowValues = null | { value: string, text?: string }[];

export class MergedTerraformSchemaArgument {
    type: TerraformValueType;
    name: string;
    description: string;
    required: boolean;
    optional: boolean;
    forceNew: boolean;
    default: string;
    maxItems: number;
    minItems: number;
    referenceResourceIds: string[];

    private _input: string;
    get input(): string {
        return this._input;
    }

    set input(v: string) {
        if (this.type === "number" && v !== "") {
            // TODO 現状だと16進数(0x999)の入力が可能だが、値の削除ができないケースがある。
            // 例: 0x12 ->(一文字削除) 0x1 : この状態だと1が削除できなくなる

            // is number?
            const n = Number(v);
            if ((typeof n !== "number") || !isFinite(n)) {
                return;
            }
        }
        this._input = v;
        this.setValue(v);
    }

    private _value: TerraformSchemaArgumentValue
    get value(): TerraformSchemaArgumentValue {
        return this._value;
    }

    set value(v: TerraformSchemaArgumentValue) {
        this._value = v;
    }

    order: number;
    ignore: boolean;
    allowValues: TerraformSchemaAllowValues;
    referenceSourcePatterns: RegExp[];
    singleSelect: boolean;
    suffix: string;

    private readonly _init: TerraformSchemaArgument;
    private readonly _def: argumentDef | undefined;

    // for view
    errors: string;

    get hasError(): boolean {
        if (this.valueIsBlockList(this.value)) {
            return this.value.some(v => v.arguments.some(a => a.hasError));
        } else {
            return this.errors !== "";
        }
    }

    constructor(init: TerraformSchemaArgument, def: argumentDef | undefined) {
        this._init = init;
        this._def = def;

        this.type = init.type;
        this.name = init.name;
        this.description = init.description;
        this.required = init.required;
        this.optional = init.optional;
        this.forceNew = init.forceNew;
        this.default = init.default;
        this.minItems = init.minItems;
        this.maxItems = init.maxItems;

        this.order = def && def.order ? def.order : 99;
        this.ignore = def ? def.ignore : false;
        this.allowValues = def && def.allowValues ? def.allowValues : null;
        this.referenceSourcePatterns = def && def.referenceSourcePatterns ? def.referenceSourcePatterns : [];
        this.singleSelect = def && def.singleSelect ? def.singleSelect : false;
        this.suffix = def && def.suffix ? def.suffix : "";

        this.errors = "";

        this._value = "";
        this._input = "";
        this.referenceResourceIds = [];
        this.resetValue();
        if (init.value) {
            this.value = init.value;
            if (this.valueIsString(init.value)) {
                this._input = init.value;
            }
            if (this.valueIsNumber(init.value)) {
                this._input = init.value.toString();
            }
        }
    }

    getDefaultValue(): boolean | string | number | null {
        if (this.default === "") {
            return null;
        }
        switch (this.type) {
            case "string" :
                return this.default;
            case "boolean" :
                if (this.default === "true") {
                    return true
                }
                return false;
            case "number" :
                return Number(this.default);
            case "stringList" :
            case "map" :
            case "block"    :
                console.log("[WARN] getDefaultValue is not supported to use with stringlist/map/block");
                return null;
        }
    }

    clone(): MergedTerraformSchemaArgument {
        const n = new MergedTerraformSchemaArgument(this._init, this._def);
        // copy runtime values
        n.allowValues = this.allowValues;
        n.referenceResourceIds = this.referenceResourceIds;
        if (this.valueIsBlockList(this.value)) {
            n.value = this.value.map(v => v.clone());
        } else {
            n.value = JSON.parse(JSON.stringify(this.value));
        }
        return n;
    }

    setValue(v: string) {
        switch (this.type) {
            case "string" :
                this.value = v;
                break;
            case "boolean" :
                if (v.toString() === "true") {
                    this.value = true;
                    break;
                }
                this.value = false;
                break;
            case "number" :
                this.value = Number(v);
                break;
            case "stringList" :
            case "map" :
            case "block"    :
                console.log("[WARN] setValue is not supported to use with stringlist/map/block", v);
                break;
        }
    }

    appendValue(v: string | MergedTerraformSchemaArgumentBlock) {
        if (!this.value) {
            this.resetValue();
        }
        if (Array.isArray(this.value)) {
            if (typeof v === "string") {
                (this.value as string[]).push(v);
            } else {
                (this.value as MergedTerraformSchemaArgumentBlock[]).push(v);
            }
        }
    }

    completeValues(blocks: MergedTerraformSchemaArgumentBlock[]) {
        if (!this.value) {
            this.resetValue();
        }
        if (Array.isArray(this.value)) {
            if (this.minItems > 0 && this.required) {
                const argBlock = blocks.find(b => b.name === this.name);
                while (this.value.length < this.minItems) {
                    if (this.valueIsStringList(this.value)) {
                        (this.value as string[]).push("");
                    } else {
                        if (argBlock) {
                            const cloned = argBlock.clone();
                            cloned.completeValues(blocks);
                            (this.value as MergedTerraformSchemaArgumentBlock[]).push(cloned);
                        } else {
                            return;
                        }
                    }
                }
            }
        }
    }

    removeValue(v: string | MergedTerraformSchemaArgumentBlock) {
        if (!this.value) {
            this.resetValue();
        }
        if (Array.isArray(this.value)) {
            if (typeof v === "string") {
                this.value = (this.value as string[]).filter(e => e !== v);
            } else {
                this.value = (this.value as MergedTerraformSchemaArgumentBlock[]).filter(e => JSON.stringify(e) !== JSON.stringify(v));
            }
        }
    }

    containsValue(v: string | number | MergedTerraformSchemaArgumentBlock): boolean {
        if (!this.value) {
            return false;
        }
        if (Array.isArray(this.value)) {
            if (typeof v === "string") {
                return (this.value as string[]).some(e => e === v);
            } else {
                return (this.value as MergedTerraformSchemaArgumentBlock[]).some(e => JSON.stringify(e) === JSON.stringify(v));
            }
        }
        return false;
    }

    valueIsString(value: TerraformSchemaArgumentValue): value is string {
        return (typeof value) === "string" && this.type === "string";
    }

    valueIsNumber(value: TerraformSchemaArgumentValue): value is number {
        return (typeof value) === "number" && this.type === "number";
    }

    valueIsBoolean(value: TerraformSchemaArgumentValue): value is boolean {
        return (typeof value) === "boolean" && this.type === "boolean";
    }

    valueIsMap(value: TerraformSchemaArgumentValue): value is Map<string,string> {
        return (value instanceof Map);
    }

    valueIsStringList(value: TerraformSchemaArgumentValue): value is string[] {
        return Array.isArray(value) && this.type === "stringList";
    }

    valueIsBlockList(value: TerraformSchemaArgumentValue): value is MergedTerraformSchemaArgumentBlock[] {
        return Array.isArray(value) && this.type === "block";
    }

    valueIsEmpty(): boolean {
        if (this.valueIsBoolean(this.value)) {
            if (this.default) {
                return this.value === this.getDefaultValue();
            }
            return this.value === false;
        }

        if (this.valueIsString(this.value)) {
            return this.value === "" || this.value === this.getDefaultValue();
        }

        if (this.valueIsNumber(this.value)) {
            return this.value === 0 || this.value === this.getDefaultValue();
        }

        if (this.valueIsMap(this.value)) {
            return this.value.size === 0;
        }

        if (Array.isArray(this.value)) {
            return this.value.length === 0;
        }

        return true;
    }


    resetValue() {
        this._input = "";
        switch (this.type) {
            case "string" :
                this.value = "";
                break;
            case "boolean" :
                this.value = false;
                break;
            case "number" :
                this.value = 0;
                break;
            case "stringList" :
                this.value = new Array<string>();
                break;
            case "map":
                this.value = new Map<string,string>();
                break;
            case "block"    :
                this.value = new Array<MergedTerraformSchemaArgumentBlock>();
                break;
        }
        this.errors = "";
        this.referenceResourceIds = [];
    }

    validate() {
        if (this.valueIsBlockList(this.value)) {
            this.value.map(v => v.arguments.map(a => a.validate()));
            return;
        }

        if (this.required && this.valueIsEmpty()) {
            this.errors = "required";
            return;
        }
        this.errors = "";
    }
}

export class MergedTerraformSchemaArgumentBlock {
    type: TerraformValueType;
    name: string;
    description: string;
    required: boolean;
    optional: boolean;
    forceNew: boolean;
    maxItems: number;
    minItems: number;
    arguments: MergedTerraformSchemaArgument[];

    private _init: TerraformSchemaArgumentBlock;
    private _def: argumentBlockDef | undefined;

    constructor(init: TerraformSchemaArgumentBlock, def: argumentBlockDef | undefined) {
        this._init = init;
        this._def = def;

        this.type = init.type;
        this.name = init.name;
        this.description = init.description;
        this.required = init.required;
        this.optional = init.optional;
        this.forceNew = init.forceNew;
        this.maxItems = init.maxItems;
        this.minItems = init.minItems;
        this.arguments = init.arguments.map(a => {
            const d = def && def.arguments ? def.arguments.find(d => d.name === a.name) : undefined;
            return new MergedTerraformSchemaArgument(a, d);
        }).sort((a, b) => a.order - b.order);
    }

    clone(): MergedTerraformSchemaArgumentBlock {
        const n = new MergedTerraformSchemaArgumentBlock(this._init, this._def);
        n.arguments = this.arguments.map(a => a.clone());
        return n;
    }

    completeValues(blocks: MergedTerraformSchemaArgumentBlock[]) {
        this.arguments.forEach(arg => {
            arg.completeValues(blocks);
        })
    }

    get referenceResourceIds(): string[] {
        return this.arguments
            .filter(v => v.referenceResourceIds.length > 0)
            .map(v => v.referenceResourceIds)
            .flat()
            .filter(v => v !== "")
    }
}


function getMergedResourceSchema(source: TerraformSchema): MergedTerraformSchema {
    const def = resourceDef.find((r) => r.name === source.name)
    return {
        id: "",
        name: source.name,
        type: source.type,
        displayName: source.displayName,
        category: source.category,
        image: def ? def.image : "",
        order: def ? def.order : 99,
        ignore: def ? def.ignore : true,
        blocks: new MergedTerraformSchemaBlock(source.schema, def)
    }
}

function getMergedDataSourceSchema(source: TerraformSchema): MergedTerraformSchema {
    const def = dataSourceDef.find((r) => r.name === source.name)
    return {
        id: "",
        name: source.name,
        type: source.type,
        displayName: source.displayName,
        category: source.category,
        image: def ? def.image : "",
        order: def ? def.order : 99,
        ignore: def ? def.ignore : true,
        blocks: new MergedTerraformSchemaBlock(source.schema, def)
    }
}
