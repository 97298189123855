import React from 'react';
import {AppContext} from "./AppContext";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import ResourceEditor from "./editor/ResourceEditor";

const theme = createMuiTheme({
    palette: {
        type: 'light',
    },
    transitions: {
        create: () => 'none',
    },
});

type Props = {
    ctx: AppContext
}

const SingleView : React.FC<Props> = (props) => {
    const ctx = props.ctx;

    return (
        <MuiThemeProvider theme={theme}>
            <ResourceEditor ctx={ctx} resource={ctx.currentItem} singleView={true}/>
        </MuiThemeProvider>
    );
}

export default SingleView;

