import React, {useEffect, useRef} from "react";
import CytoscapeComponent from "react-cytoscapejs";
import cytoscape, {Collection} from "cytoscape";
import {AppContext} from "./AppContext";
import {Resource} from "../lib/resource";

const layout = {
    name: 'breadthfirst',
    grid: true,
    animate: false,
    fit: false,
    column: 5, // TODO エッジ実装時に調整
};

const stylesheet = [
    {
        selector: "node",
        style: {
            'width': 80,
            'height': 80,
            'content': 'data(label)',
            "background-color": "#FFF",
            'shape': 'rectangle',
            "background-fit": "contain",
        }
    },
    {
        selector: "edge.taxi",
        style: {
            "curve-style": "taxi",
            "taxi-turn": 20,
            "taxi-turn-min-distance": 5,
        }
    },
];

const containerStyle: React.CSSProperties = {
    backgroundColor: "#FFF",
    backgroundImage: "linear-gradient(transparent 95%, rgba(0, 0, 0, .06) 50%, rgba(0, 0, 0, .06)), linear-gradient(90deg, transparent 95%, rgba(0, 0, 0, .06) 50%, rgba(0, 0, 0, .06))",
    backgroundSize: "16px 16px",
    backgroundRepeat: "repeat",
    minWidth: "600px",
    minHeight: "400px",
    border: "1px solid #ddd",
};

type Props = {
    ctx: AppContext
    hidden: boolean
}

const GraphCanvas: React.FC<Props> = props => {
    const ctx = props.ctx;
    const elements = ctx.graphElements;
    const cyRef = useRef<cytoscape.Core>();

    // elementsが増えた場合の再配置
    useEffect(() => {
        if (cyRef.current) {
            cyRef.current.layout(layout).run();
        }
        ctx.resourceOpenStateHandler = (r: Resource) => {
            if (!r.open && cyRef.current) {
                cyRef.current.nodes().unselect();
            }
        }
    }, [ctx, cyRef, elements]);

    useEffect(() => {
        if (cyRef.current) {
            // setup cy depended properties
            ctx.setViewPort({height: cyRef.current.height(), width: cyRef.current.width()});

            cyRef.current.on("select", "node", (e) => {
                const targets: Collection = e.target;
                targets.map(ele => ctx.propagateCurrentNodeValue(ele.id(), false));
            });
            cyRef.current.on("unselect", "node", (e) => {
                const targets: Collection = e.target;
                targets.map(ele => ctx.propagateCurrentNodeValue(ele.id(), true));
            });
            cyRef.current.on("resize", () => {
                if (!props.hidden && cyRef.current) {
                    cyRef.current.layout(layout).run();
                }
            });
        }

        return () => {
            if (cyRef.current) {
                cyRef.current.off("click", "node")
                cyRef.current.off("select", "node")
                cyRef.current.off("unselect", "node")
                cyRef.current.off("resize")
            }
        };
    }, [ctx, cyRef, props.hidden]);

    const mergedStylesheet = [...stylesheet, ...ctx.elementStyles];
    return (
        <>
            <CytoscapeComponent
                id="cy"
                elements={elements}
                style={{...containerStyle, display: props.hidden ? "none" : "inherit"}}
                layout={layout}
                stylesheet={mergedStylesheet}
                cy={(cy) => {
                    cyRef.current = cy;
                }}
                userZoomingEnabled={false}
                userPanningEnabled={false}
                boxSelectionEnabled={false}
                autolock={false} // TODO デバッグ
                maxZoom={1}
            />
        </>
    );
};

export default GraphCanvas;
