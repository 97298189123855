import React from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {AppContext} from "./AppContext";
import {Resource} from "../lib/resource";
import {createStyles, Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

type Props = {
    className?: string
    resource: Resource
    ctx: AppContext
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            paddingLeft: theme.spacing(7),
        },
        icon: {
            width: 30,
            height: 30,
        },
        opened: {
            borderRight: "3px solid blue",
        },
    }),
);

const Item: React.FC<Props> = props => {
    const classes = useStyles();
    const r = props.resource;

    const onItemClick = () => {
        props.ctx.currentItem = r;
    };

    // const handleClose = () => {
    //     props.ctx.currentItem = null;
    // };

    return (
        <>
            <ListItem button onClick={onItemClick} className={classes.item} selected={r.open} classes={{selected: classes.opened}}>
                <ListItemAvatar>
                    <Avatar alt={r.name} className={classes.icon} src={r.image} variant="square"
                            imgProps={{draggable: false}}/>
                </ListItemAvatar>
                <ListItemText primary={r.displayName} primaryTypographyProps={{variant: "subtitle2"}}/>
            </ListItem>
            {/*<ItemDetailDialog open={r.open} onClose={handleClose} ctx={props.ctx} resource={r}/>*/}
        </>
    );
};

export default Item;
