import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

type Props = {
    title?: string
    children: React.ReactElement;
}

const useStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        fontSize: 16,
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 600,
    },
}));

const DescTooltip: React.FC<Props> = (props) => {
    const classes = useStyles();

    const elements = [];
    if (props.title && props.title.length > 0) {
        const reg = /([^`]*)`([^`]*)`([^`]*)/g
        let match = reg.exec(props.title);
        let i = 0;
        do {
            if (match !== null && match.length === 4) {
                elements.push(<span key={i}>{match[1]}<b>{match[2]}</b>{match[3]}</span>);
            } else {
                elements.push(props.title);
            }
            i++;
        } while ((match = reg.exec(props.title)) !== null);
    }

    return (
        <Tooltip title={<React.Fragment>{elements}</React.Fragment>}
                 placement={"top-start"}
                 classes={{tooltip: classes.tooltip}}
                 disableFocusListener={elements.length === 0}
                 disableHoverListener={elements.length === 0}
                 disableTouchListener={elements.length === 0}
        >
            {props.children}
        </Tooltip>
    )
};

export default DescTooltip;