import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import Input from "@material-ui/core/Input";
import ErrorTooltip from "./ErrorTooltip";

type Props = {
    inputType: string
    fieldName: string
    required ?: boolean
    placeholder?:string
    value: string | number
    error: boolean
    helperText?: string
    onChange ?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    //children: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
    inputs: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        //backgroundColor: "rgba(224,224,224,0.8)",
        backgroundColor: "white",
        minWidth: "100px",
        borderRadius: 5,
    },
    base: {
        padding: "3px 0 3px",
    },
    error: {
        border: "2px solid red",
        borderRadius: 5,
        backgroundColor: red[50],
    },
}));

const TextInput: React.FC<Props> = (props) => {
    const classes = useStyles();
    const placeholder = props.placeholder ?  props.placeholder: "";

    return <ErrorTooltip title={props.helperText} open={props.error}>
        <Input
            id={`${props.fieldName}-id`}
            classes={{error: classes.error, input:classes.base}}
            className={classes.inputs}
            placeholder={`${props.required ? "* ": ""}${placeholder}`}
            error={props.error}
            style={{width: `${(props.value === "" ? placeholder.length : props.value.toString().length) + 2}ch`}} // 両サイドに1文字分余裕を持たせる
            onChange={(e) => {
                if (props.onChange) {
                    props.onChange(e)
                }
            }}
            disableUnderline={true}
            value={props.value}
        />
    </ErrorTooltip>
};

export default TextInput;