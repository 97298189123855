import {Resource} from "../../lib/resource";
import {calcNameAdjustSpaceLength, MergedTerraformSchemaArgument} from "../../lib/schema";
import React from "react";
import TextInput from "./TextInput";
import {Typography} from "@material-ui/core";
import Token from "./Token";
import Line from "./Line";
import Tokens from "./Tokens";
import SelectInput from "./SelectInput";
import CheckListInput from "./CheckListInput";
import {green} from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AutocompleteInput from "./AutocompleteInput";
import CheckInput from "./CheckInput";
import DescTooltip from "./DescTooltip";

const indentSize = 2;

export const renderFields = (props: { resource: Resource, handler: () => void }) => {
    const adjustLen = calcNameAdjustSpaceLength(props.resource.blocks.arguments, true);

    return props.resource.sortedArguments
        .map(arg => renderField({
            resourceName: props.resource.name,
            resource: props.resource,
            tfArg: arg,
            handler: props.handler,
            indent: 1,
            adjustLen: adjustLen,
        }))
};

type renderFieldProp = {
    resourceName: string
    resource: Resource
    tfArg: MergedTerraformSchemaArgument
    handler: () => void
    indent: number
    adjustLen: number
}

const renderField = (props: renderFieldProp) => {
    switch (props.tfArg.type) {
        case "string" :
        case "number" :
            if (props.tfArg.referenceSourcePatterns.length > 0 && props.tfArg.allowValues !== null && props.tfArg.allowValues.length > 0) {
                return renderAutoCompleteTextField(props);
            }
            if (props.tfArg.allowValues != null) {
                return renderSelectField(props);
            }
            return renderTextField(props);
        case "boolean" :
            return renderCheckField(props);
        case "stringList" :
            if (props.tfArg.referenceSourcePatterns.length === 0 && props.tfArg.allowValues != null && !props.tfArg.singleSelect) {
                return renderCheckListField(props)
            }
            // 動的な項目
            return renderDynamicTextField(props);
        case "map":
            // 動的な項目
            return renderDynamicKeyValueField(props);
        case "block":
            return renderDynamicBlock(props);
    }
};

const renderTextField = (props: renderFieldProp) => {
    const {indent, adjustLen} = props;
    const s = props.tfArg;
    const def = s.getDefaultValue();
    if (!s.valueIsString(s.value) && !s.valueIsNumber(s.value)) {
        console.log("[WARN] renderTextField: value is not string|number", s.value);
        return;
    }
    const isString = s.valueIsString(s.value);
    return (
        <Line indent={indent * indentSize} key={`${props.resourceName}-${s.name}`}>
            <Tokens>
                <Token type={"token"}>
                    <DescTooltip
                        title={s.description}><span>{s.name}</span></DescTooltip>{" ".repeat(adjustLen - s.name.length)}
                </Token>
                <Token>=</Token>
                <Token>
                    <TextInput
                        inputType={isString ? "text" : "number"}
                        required={s.required}
                        fieldName={`${props.resourceName}-${s.name}`}
                        error={s.hasError}
                        helperText={s.errors}
                        value={s.input}
                        placeholder={def ? def.toString() : s.name}
                        onChange={(e) => {
                            s.input = e.target.value;
                            s.validate();
                            props.handler();
                        }}
                    />
                </Token>
                <Token>
                    <Typography key={`${props.resourceName}-${s.name}-suffix`} variant="overline"
                                display="block">{s.suffix}</Typography>
                </Token>
            </Tokens>
        </Line>
    );
};


const renderAutoCompleteTextField = (props: renderFieldProp) => {
    const {indent, adjustLen} = props;
    const s = props.tfArg;
    if (s.valueIsEmpty()) {
        s.value = s.default;
    }
    if (!s.allowValues) {
        s.allowValues = [];
    }
    if (!s.valueIsString(s.value) && !s.valueIsNumber(s.value)) {
        console.log("[WARN] renderTextField: value is not string|number", s.value);
        return;
    }
    return (
        <Line indent={indent * indentSize} key={`${props.resourceName}-${s.name}`}>
            <Tokens>
                <Token type={"token"}>
                    <DescTooltip
                        title={s.description}><span>{s.name}</span></DescTooltip>{" ".repeat(adjustLen - s.name.length)}
                </Token>
                <Token>=</Token>
                <Token>
                    <AutocompleteInput
                        required={s.required}
                        fieldName={`${props.resourceName}-${s.name}`}
                        error={s.hasError}
                        helperText={s.errors}
                        value={s.value as string}
                        placeholder={s.name}
                        allowValues={s.allowValues}
                        onChange={(e, value) => {
                            s.referenceResourceIds = [];
                            if (s.allowValues && s.allowValues.length > 0) {
                                const allowValue = s.allowValues.find(v => v.text === value);
                                if (allowValue) {
                                    s.referenceResourceIds = [allowValue.value];
                                }
                            }
                            s.setValue(value);
                            s.validate();
                            props.handler();
                        }}
                    />
                </Token>
                <Token>
                    <Typography key={`${props.resourceName}-${s.name}-suffix`} variant="overline"
                                display="block">{s.suffix}</Typography>
                </Token>
            </Tokens>
        </Line>
    );
};

const renderCheckField = (props: renderFieldProp) => {
    const {indent, adjustLen} = props;
    const s = props.tfArg;
    if (s.valueIsEmpty()) {
        const def = s.getDefaultValue();
        if (def) {
            s.value = def;
        }
    }
    if (!s.valueIsBoolean(s.value)) {
        console.log("[WARN] renderCheckField: value is not a boolean", s.value);
        return;
    }
    return (
        <Line indent={indent * indentSize} key={`${props.resourceName}-${s.name}`}>
            <Tokens>
                <Token type={"token"}>
                    <DescTooltip
                        title={s.description}><span>{s.name}</span></DescTooltip>{" ".repeat(adjustLen - s.name.length)}
                </Token>
                <Token>=</Token>
                <Token>
                    <CheckInput
                        fieldName={`${props.resourceName}-${s.name}`}
                        error={s.hasError}
                        helperText={s.errors}
                        value={s.value}
                        onChange={(e, checked) => {
                            s.value = checked;
                            s.validate();
                            props.handler();
                        }}
                    />
                </Token>
            </Tokens>
        </Line>
    );
};


const renderSelectField = (props: renderFieldProp) => {
    const {indent, adjustLen} = props;
    const s = props.tfArg;
    if (!s.allowValues) {
        console.log("[WARN] renderSelectField: allowValues is not set", s);
        return
    }
    if (!s.valueIsString(s.value)) {
        console.log("[WARN] renderTextField: value is not string", s.value);
        return;
    }
    const values = s.allowValues;
    return (
        <Line indent={indent * indentSize} key={`${props.resourceName}-${s.name}`}>
            <Tokens>
                <Token type={"token"}>
                    <DescTooltip
                        title={s.description}><span>{s.name}</span></DescTooltip>{" ".repeat(adjustLen - s.name.length)}
                </Token>
                <Token>=</Token>
                <Token>
                    <SelectInput
                        required={s.required}
                        fieldName={`${props.resourceName}-${s.name}`}
                        placeholder={s.name}
                        error={s.hasError}
                        helperText={s.errors}
                        value={s.value}
                        values={values}
                        onChange={(e) => {
                            s.setValue(e.target.value as string);
                            s.validate();
                            props.handler();
                        }}
                    />
                </Token>
                <Token>
                    <Typography key={`${props.resourceName}-${s.name}-suffix`} variant="overline"
                                display="block">{s.suffix}</Typography>
                </Token>
            </Tokens>
        </Line>
    );
};

const renderCheckListField = (props: renderFieldProp) => {
    const {indent, adjustLen} = props;
    const s = props.tfArg;
    if (!s.allowValues) {
        console.log("[WARN] renderCheckField: allowValues is not set", s);
        return
    }
    if (!s.valueIsStringList(s.value)) {
        console.log("[WARN] renderCheckField: value is not stringList", s.value);
        return;
    }
    const values = s.allowValues;
    return (
        <div key={`${props.resourceName}-${s.name}`}>
            <Line indent={indent * indentSize}>
                <Token type={"token"}>
                    <DescTooltip
                        title={s.description}><span>{s.name}</span></DescTooltip>{" ".repeat(adjustLen - s.name.length)}
                </Token>
                <Token> </Token>
                <Token>=</Token>
                <Token> </Token>
                <Token>[</Token>
                <Token> </Token>
            </Line>
            <Line indent={(indent + 1) * indentSize}>
                <Token>
                    <CheckListInput
                        required={s.required}
                        fieldName={`${props.resourceName}-${s.name}`}
                        error={s.hasError}
                        helperText={s.errors}
                        value={s.value}
                        values={values}
                        onChange={(e, checked) => {
                            const v = e.target.value;
                            s.removeValue(v);
                            if (checked) {
                                s.appendValue(v)
                            }
                            s.validate();
                            props.handler();
                        }}
                    />
                </Token>
            </Line>
            <Line indent={indent * indentSize} key={`${props.resourceName}-${s.name}`}>
                <Token> </Token>
                <Token>]</Token>
            </Line>
        </div>
    );
};


const renderDynamicTextField = (props: renderFieldProp) => {
    const {indent, adjustLen} = props;
    const s = props.tfArg;
    if (!s.value) {
        s.resetValue();
    }
    if (!s.valueIsStringList(s.value)) {
        console.log("[WARN] renderDynamicTextField: value is not a string[]");
        return;
    }
    return (
        <div key={`${props.resourceName}-${s.name}`}>
            <Line indent={indent * indentSize}>
                <Tokens>
                    <Token type={"token"}>
                        <DescTooltip
                            title={s.description}><span>{s.name}</span></DescTooltip>{" ".repeat(adjustLen - s.name.length)}
                    </Token>
                    <Token>=</Token>
                    <Token>[</Token>
                </Tokens>
            </Line>
            {s.value.length > 0 && s.value.map((value, i) => (
                <Line indent={(indent + 1) * indentSize} key={`${props.resourceName}-${s.name}-${i}-line`}>
                    <Token>
                        {(s.referenceSourcePatterns.length === 0 || !s.allowValues || s.allowValues.length === 0) &&
                        <TextInput
                            inputType={"text"}
                            required={s.required}
                            fieldName={`${props.resourceName}-${s.name}`}
                            error={s.hasError} // TODO リスト項目でのエラーの出し方を考える
                            helperText={s.errors}
                            value={value}
                            placeholder={s.name}
                            onChange={(e) => {
                                if (!s.valueIsStringList(s.value)) {
                                    console.log("[WARN] value is not a StringList");
                                    return;
                                }
                                s.value[i] = e.target.value;
                                s.validate();
                                props.handler();
                            }}
                        />
                        }
                        {s.referenceSourcePatterns.length > 0 && s.allowValues && s.allowValues.length > 0 &&
                        <AutocompleteInput
                            required={s.required}
                            fieldName={`${props.resourceName}-${s.name}`}
                            error={s.hasError}
                            helperText={s.errors}
                            value={value}
                            placeholder={s.name}
                            allowValues={s.allowValues}
                            onChange={(e, value) => {
                                if (!s.valueIsStringList(s.value)) {
                                    return;
                                }
                                s.value[i] = value;
                                // TODO referenceResourceIdを複数持てるようにしなきゃだめ
                                if (s.allowValues && s.allowValues.length > 0) {
                                    const allowValue = s.allowValues.find(v => v.text === value);
                                    if (allowValue) {
                                        s.referenceResourceIds[i] = allowValue.value;
                                    }
                                }
                                s.validate();
                                props.handler();
                            }}
                        />
                        }
                    </Token>
                    {(s.minItems === 0 || s.minItems <= i) &&
                    <IconButton style={{paddingLeft: "6px", marginRight: "6px"}}
                                aria-label="remove"
                                color="secondary"
                                onClick={(e) => {
                                    if (!s.valueIsStringList(s.value)) {
                                        return;
                                    }
                                    s.value.splice(i, 1);
                                    s.referenceResourceIds.splice(i,1);
                                    props.handler();
                                }}
                    >
                        <RemoveCircleIcon/>
                    </IconButton>
                    }
                </Line>
            ))}
            {(s.maxItems === 0 || s.value.length < s.maxItems) &&
            <Line indent={(indent + 1) * indentSize}>
                <Token>
                    <IconButton aria-label="add" onClick={(e) => {
                        if (!s.valueIsStringList(s.value)) {
                            console.log("[WARN] value is not a StringList");
                            return;
                        }
                        s.value.push("");
                        s.referenceResourceIds.push("");
                        props.handler();
                    }}>
                        <AddCircleIcon style={{color: green[500]}}/>
                    </IconButton>
                </Token>
            </Line>
            }
            <Line indent={indent * indentSize}>
                <Token>]</Token>
            </Line>
        </div>
    );
};


const renderDynamicBlock = (props: renderFieldProp) => {
    const indent = props.indent;
    const s = props.tfArg;
    if (!s.value) {
        s.resetValue();
    }

    if (!s.valueIsBlockList(s.value)) {
        console.log("[WARN] value is not a TerraformSchemaArgumentValue[]");
        return;
    }

    if (!s.valueIsBlockList(s.value)) {
        console.log("[WARN] value is not a TerraformSchemaArgumentValue[]");
        return;
    }

    // copy from schema blocks
    const block = props.resource.argumentBlockByName(s.name);
    if (!block) {
        console.log(`[WARN] block[${s.name}] is not found`);
        return;
    }

    const addHandler = () => {
        s.appendValue(block.clone());
        props.handler();
    };

    const args = s.value.map((block, i) => {
        const adjustLen = calcNameAdjustSpaceLength(block.arguments, true);
        // TODO sortをするべき
        const inners = block.arguments.filter(a => !a.ignore).map((a) => {
            return renderField({
                resourceName: `${props.resourceName}-${s.name}-${block.name}-${i}`,
                tfArg: a,
                handler: props.handler,
                resource: props.resource,
                indent: props.indent + 1,
                adjustLen: adjustLen,
            });
        });
        return (
            <div key={`${props.resourceName}-${s.name}-${block.name}-${i}-wrapper`}>
                <Line indent={indent * indentSize}>
                    <Tokens>
                        <Token type={"token"}>
                            <DescTooltip title={s.description}><span>{s.name}</span></DescTooltip>
                        </Token>
                        <Token>{"{"}</Token>
                    </Tokens>
                </Line>
                {inners}
                <Line indent={indent * indentSize} key={`${props.resource.name}-${s.name}`}>
                    <Token>{"}"}</Token>
                    {(s.minItems === 0 || s.minItems <= i) &&
                    <IconButton aria-label="remove" color="secondary" onClick={(e) => {
                        if (!s.valueIsBlockList(s.value)) {
                            return;
                        }
                        s.value.splice(i, 1);
                        props.handler();
                    }}>
                        <RemoveCircleIcon/>
                    </IconButton>
                    }
                </Line>
            </div>
        );
    });

    return (
        <div key={`${props.resource.name}-${s.name}`}>
            {args}
            {(block.maxItems === 0 || s.value.length < block.maxItems) &&
            <>
                <Line indent={(indent) * indentSize}>
                    <Tokens>
                        <Token type={"token"}>
                            <DescTooltip title={s.description}><span>{s.name}</span></DescTooltip>
                        </Token>
                        <Token>{"{"}</Token>
                    </Tokens>
                </Line>
                <Line indent={(indent + 1) * indentSize}>
                    <Token>
                        <IconButton aria-label="add" onClick={addHandler}>
                            <AddCircleIcon style={{color: green[500]}}/>
                        </IconButton>
                    </Token>
                </Line>
                <Line indent={(indent) * indentSize}>
                    <Token>{"}"}</Token>
                </Line>
            </>
            }
        </div>
    )
};

const renderDynamicKeyValueField = (props: renderFieldProp) => {
    const {indent, adjustLen} = props;
    const s = props.tfArg;
    if (!s.value || !s.valueIsMap(s.value)) {
        s.resetValue();
    }
    if (!s.valueIsMap(s.value)) {
        console.log("[WARN] renderDynamicKeyValueField: value is not a Map<string,string>");
        return;
    }
    const keys = Array.from(s.value.keys());

    const getValue = (m: Map<string,string>, key : string): string =>{
        const v = m.get(key);
        return v ? v : "";
    }

    return (
        <div key={`${props.resourceName}-${s.name}`}>
            <Line indent={indent * indentSize}>
                <Tokens>
                    <Token type={"token"}>
                        <DescTooltip
                            title={s.description}><span>{s.name}</span></DescTooltip>{" ".repeat(adjustLen - s.name.length)}
                    </Token>
                    <Token>=</Token>
                    <Token>{"{"}</Token>
                </Tokens>
            </Line>
            {s.value.size > 0 && keys.map((value, i) => (
                <Line indent={(indent + 1) * indentSize} key={`${props.resourceName}-${s.name}-${i}-line`}>
                    <Token>
                        <TextInput
                            inputType={"text"}
                            required={s.required}
                            fieldName={`${props.resourceName}-${s.name}-key`}
                            error={s.hasError} // TODO リスト項目でのエラーの出し方を考える
                            helperText={s.errors}
                            value={value}
                            placeholder={s.name}
                            onChange={(e) => {
                                if (!s.valueIsMap(s.value)) {
                                    console.log("[WARN] value is not a Map<string,string>");
                                    return;
                                }
                                const current = s.value.get(value);
                                s.value.delete(value);
                                s.value.set(e.target.value, current ? current: "");
                                s.validate();
                                props.handler();
                            }}
                        />
                    </Token>
                    <Token> = </Token>
                    <Token>
                        <TextInput
                            inputType={"text"}
                            required={s.required}
                            fieldName={`${props.resourceName}-${s.name}-value`}
                            error={s.hasError} // TODO リスト項目でのエラーの出し方を考える
                            helperText={s.errors}
                            value={getValue((s.value as Map<string,string>),value)}
                            placeholder={s.name}
                            onChange={(e) => {
                                if (!s.valueIsMap(s.value)) {
                                    console.log("[WARN] value is not a Map<string,string>");
                                    return;
                                }
                                s.value.set(value, e.target.value);
                                s.validate();
                                props.handler();
                            }}
                        />
                    </Token>
                    {(s.minItems === 0 || s.minItems <= i) &&
                    <IconButton style={{paddingLeft: "6px", marginRight: "6px"}}
                                aria-label="remove"
                                color="secondary"
                                onClick={(e) => {
                                    if (!s.valueIsMap(s.value)) {
                                        console.log("[WARN] value is not a Map<string,string>");
                                        return;
                                    }
                                    s.value.delete(value);
                                    props.handler();
                                }}
                    >
                        <RemoveCircleIcon/>
                    </IconButton>
                    }
                </Line>
            ))}
            {(s.maxItems === 0 || s.value.size < s.maxItems) &&
            <Line indent={(indent + 1) * indentSize}>
                <Token>
                    <IconButton aria-label="add" onClick={(e) => {
                        if (!s.valueIsMap(s.value)) {
                            console.log("[WARN] value is not a Map<string,string>");
                            return;
                        }
                        s.value.set("", "");
                        props.handler();
                    }}>
                        <AddCircleIcon style={{color: green[500]}}/>
                    </IconButton>
                </Token>
            </Line>
            }
            <Line indent={indent * indentSize}>
                <Token>{"}"}</Token>
            </Line>
        </div>
    );
};
