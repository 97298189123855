import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginBottom: 10,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            marginLeft: theme.spacing(6),
        },
    }),
);

export default function TopBar() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">*/}
                        {/*<MenuIcon />*/}
                    {/*</IconButton>*/}
                    <Typography variant="h5" className={classes.title}>
                       Zouen - Terraformコードジェネレータ for さくらのクラウド(ver: 0.0.1-alpha0)
                    </Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}