import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core";

export type TokenType  = "normal" | "token" | "keyword" | "value" | "error"

type Props = {
    type?: TokenType
    children: React.ReactNode
    quote?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    normal: {
        color: "white",
    },
    token: {
        color: "rgb(209, 148, 158)",
    },
    keyword: {
        color: "rgb(245, 184, 61)",
    },
    value: {
        color: "white",
        //color: "rgb(189, 225, 81)",
    },
}));

const Token: React.FC<Props> = (props) => {
    const classes = useStyles();
    let className;
    switch (props.type) {
        case "token":
            className = classes.token;
            break;
        case "keyword":
            className = classes.keyword;
            break;
        case "value":
            className = classes.value;
            break;
        default:
            className = classes.normal;
    }
    const quote = props.quote ? `"`: "";
    return <span className={className}>{quote}{props.children}{quote}</span>
};

export default Token;